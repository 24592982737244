import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode } from "react";

interface locationProps {
  handleChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  location: string;
}

const LocationDropDown = ({ handleChange, location }: locationProps) => {
  return (
    <>
      <FormControl sx={{ width: 1 }}>
        <InputLabel>Locations *</InputLabel>
        <Select
          required
          value={location}
          label="Locations"
          onChange={handleChange}
        >
          <MenuItem value={"Preston Ford"}>Preston Ford</MenuItem>
          <MenuItem value={"Preston Hyundai"}>Preston Hyundai</MenuItem>
          <MenuItem value={"Preston Nissan"}>Preston Nissan</MenuItem>
          <MenuItem value={"Preston Mazda"}>Preston Mazda</MenuItem>
          <MenuItem value={"Preston Ford of Denton"}>
            Preston Ford of Denton
          </MenuItem>
          <MenuItem value={"Preston Ford West"}>Preston Ford West</MenuItem>
          <MenuItem value={"Pittsville Ford"}>Pittsville Ford</MenuItem>
          <MenuItem value={"Quick Lane of Pittsville"}>
            Quick Lane of Pittsville
          </MenuItem>
          <MenuItem value={"Boulevard Ford Georgetown"}>
            Boulevard Ford Georgetown
          </MenuItem>
          <MenuItem value={"Boulevard Ford Lewes"}>
            Boulevard Ford Lewes
          </MenuItem>
          <MenuItem value={"Preston CDJR"}>Preston CDJR</MenuItem>
          <MenuItem value={"Preston Hyundai of Millsboro"}>
            Preston Hyundai of Millsboro
          </MenuItem>
          <MenuItem value={"Preston CDJR of Wilmington"}>
            Preston CDJR of Wilmington
          </MenuItem>
          <MenuItem value={"Preston CDJR of Dover"}>
            Preston CDJR of Dover
          </MenuItem>
          <MenuItem value={"Preston Auto Body of Dover"}>
            Preston Auto Body of Dover
          </MenuItem>
          <MenuItem value={"Preston Auto Body"}>Preston Auto Body</MenuItem>
          <MenuItem value={"Cambridge Auto Body"}>Cambridge Auto Body</MenuItem>
          <MenuItem value={"Snow Hill Auto Body"}>Snow Hill Auto Body</MenuItem>
          <MenuItem value={"Preston Auto Body of Wilmington"}>
            Preston Auto Body of Wilmington
          </MenuItem>
          <MenuItem value={"Preston Ford Keller"}>Preston Ford Keller</MenuItem>
          <MenuItem value={"Preston Ford Aberdeen"}>
            Preston Ford Aberdeen
          </MenuItem>
          <MenuItem value={"Preston Chevrolet of Aberdeen"}>
            Preston Chevrolet of Aberdeen
          </MenuItem>
          <MenuItem value={"Preston Ford Commercial"}>
            Preston Ford Commercial
          </MenuItem>
          <MenuItem value={"Mobile Service"}>Mobile Service</MenuItem>
          <MenuItem value={"Preston Automotive Group"}>
            Preston Automotive Group
          </MenuItem>
          <MenuItem value={"Auto Express"}>Auto Express</MenuItem>
          <MenuItem value={"Preston Powersports"}>Preston Powersports</MenuItem>
          <MenuItem value={"Preston Lawn & Equipment"}>
            Preston Lawn & Equipment
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default LocationDropDown;
