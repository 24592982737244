type locationDetailsTypes = {
  [key: string]: {
    address: string;
    city: string;
    state: string;
    zip: string;
    officeNumber: string;
    website: string;
  };
};
export const locationDetails: locationDetailsTypes = {
  "Preston Ford": {
    address: "4313 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonFord.com",
  },
  "Preston Hyundai": {
    address: "4327 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonHyundaiUSA.com",
  },
  "Preston Nissan": {
    address: "4307 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonNissan.com",
  },
  "Preston Mazda": {
    address: "4309 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonMazda.com",
  },
  "Preston Ford of Denton": {
    address: "1207 Double Hills Rd.",
    city: "Denton",
    state: "MD",
    zip: "21629",
    officeNumber: "410-479-2660",
    website: "DentonFord.com",
  },
  "Preston Ford West": {
    address: "9311 Liberty Rd.",
    city: "Randallstown",
    state: "MD",
    zip: "21133",
    officeNumber: "410-655-2000",
    website: "PrestonFordWest.com",
  },
  "Pittsville Ford": {
    address: "7155 Friendship Rd.",
    city: "Pittsville",
    state: "MD",
    zip: "21850",
    officeNumber: "410-835-8338",
    website: "PittsvilleFord.com",
  },
  "Quick Lane of Pittsville": {
    address: "34690 Old Ocean City Rd.",
    city: "Pittsville",
    state: "MD",
    zip: "21850",
    officeNumber: "410-835-8339",
    website: "",
  },
  "Boulevard Ford Georgetown": {
    address: "40 Bridgeville Rd.",
    city: "Georgetown",
    state: "DE",
    zip: "19947",
    officeNumber: "302-856-2561",
    website: "BoulevardFordLincoln.com",
  },
  "Boulevard Ford Lewes": {
    address: "18443 Coastal Hwy.",
    city: "Lewes",
    state: "DE",
    zip: "19958",
    officeNumber: "302-645-2801",
    website: "BlvdFord.com",
  },
  "Preston CDJR": {
    address: "28380 Dupont Blvd.",
    city: "Millsboro",
    state: "DE",
    zip: "19966",
    officeNumber: "302-934-8282",
    website: "MillsboroCDJR.com",
  },
  "Preston Hyundai of Millsboro": {
    address: "28362 Dupont Blvd.",
    city: "Millsboro",
    state: "DE",
    zip: "19966",
    officeNumber: "302-297-4200",
    website: "MillsboroHyundai.com",
  },
  "Preston CDJR of Wilmington": {
    address: "3807 Kirkwood Hwy.",
    city: "Wilmington",
    state: "DE",
    zip: "19808",
    officeNumber: "302-998-2271",
    website: "WilmingtonCDJR.com",
  },
  "Preston CDJR of Dover": {
    address: "640 S. Governors Ave.",
    city: "Dover",
    state: "DE",
    zip: "19904",
    officeNumber: "302-734-5708",
    website: "DoverCDJR.com",
  },
  "Preston Auto Body of Dover": {
    address: "1362 Forrest Ave.",
    city: "Dover",
    state: "DE",
    zip: "19904",
    officeNumber: "302-734-5708",
    website: "DoverCollision.com",
  },
  "Preston Auto Body": {
    address: "218 S. Main St.",
    city: "Preston",
    state: "MD",
    zip: "21655",
    officeNumber: "410-673-7100",
    website: "PrestonAutoBody.com",
  },
  "Cambridge Auto Body": {
    address: "2829 Gypsy Hill Rd.",
    city: "Cambridge",
    state: "MD",
    zip: "21613",
    officeNumber: "410-228-3764",
    website: "CambridgeAutoBody.com",
  },
  "Snow Hill Auto Body": {
    address: "5761 Worcester Hwy.",
    city: "Snow Hill",
    state: "MD",
    zip: "21863",
    officeNumber: "410-632-2044",
    website: "SnowHillAutoBody.com",
  },
  "Preston Auto Body of Wilmington": {
    address: "4800 Kirkwood Hwy.",
    city: "Wilmington",
    state: "DE",
    zip: "19808",
    officeNumber: "302-999-0541",
    website: "WilmingtonCollision.com",
  },
  "Preston Ford Keller": {
    address: "31066 Lankford Hwy.",
    city: "Keller",
    state: "VA",
    zip: "23401",
    officeNumber: "757-787-1209",
    website: "PrestonFordofKeller.com",
  },
  "Preston Ford Aberdeen": {
    address: "505 S. Philadelphia Blvd.",
    city: "Aberdeen",
    state: "MD",
    zip: "21001",
    officeNumber: "410-272-2200",
    website: "PrestonFordAberdeen.com",
  },
  "Preston Chevrolet of Aberdeen": {
    address: "101 N. Philadelphia Blvd.",
    city: "Aberdeen",
    state: "MD",
    zip: "21001",
    officeNumber: "410-272-0300",
    website: "PrestonChevy.com",
  },
  "Preston Ford Commercial": {
    address: "4313 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonCommercial.com",
  },
  "Mobile Service": {
    address: "4313 Preston Rd.",
    city: "Hurlock",
    state: "MD",
    zip: "21643",
    officeNumber: "410-673-7171",
    website: "PrestonFord.com",
  },
  "Preston Automotive Group": {
    address: "218 S. Main St.",
    city: "Preston",
    state: "MD",
    zip: "21655",
    officeNumber: "410-673-7171",
    website: "PrestonMotor.com",
  },
  "Auto Express": {
    address: "30444 Landford Hwy.",
    city: "Melfa",
    state: "VA",
    zip: "23410",
    officeNumber: "757-302-0313",
    website: "KellerAutoExpress.com",
  },
  "Preston Powersports": {
    address: "9477 Ocean Gateway",
    city: "Easton",
    state: "MD",
    zip: "21601",
    officeNumber: "410-822-1766",
    website: "",
  },
  "Preston Lawn & Equipment": {
    address: "6505 Old Trappe Rd.",
    city: "Trappe",
    state: "MD",
    zip: "21673",
    officeNumber: "410-822-8866",
    website: "",
  },
};
