/* eslint-disable jsx-a11y/alt-text */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import frog from "../assets/frog_preston_logo.png";

function Header() {
  return (
    <AppBar position="absolute" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ marginInline: { xs: "auto", sm: 0 } }}>
            <a href="https://www.prestonmotor.com/">
              <img
                src={frog}
                style={{
                  maxWidth: "200px",
                  maxHeight: "200px",
                  margin: "1em",
                  padding: 0,
                }}
              />
            </a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
